import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useFormik} from 'formik'
import React, {useContext, useEffect, useState} from 'react'
import {boolean, date, number, object, string} from 'yup'
import {useBlocker, useNavigate} from 'react-router-dom'
import { useBeforeunload } from 'react-beforeunload';
import Button from 'react-bootstrap/Button'
import addDays from 'date-fns/addDays'
import Spinner from 'react-bootstrap/Spinner'
import PropTypes from 'prop-types'
import {parseISO} from 'date-fns'

import Box from '../../ui/box/Box'
import {ReceiverForm} from './ReceiverForm'
import Title from '../../ui/page/Title'
import {SenderForm} from './SenderForm'
import {MessageForm} from './MessageForm'
import {ProductForm} from './ProductForm'
import {DeliveryForm} from './DeliveryForm'
import {PriceForm} from './PriceForm'
import {parseFloatCustom} from '../../../Tools/Numbers'
import AuthContext from '../../../contexts/AuthContext'
import CommandeAPI from '../../../services/CommandeAPI'
import {SummaryModal} from './SummaryModal'
import {ConfirmationModal} from './ConfirmationModal'
import {Dirty} from '../../ui/Dirty'
import {TRANSMISSIONS_PATH} from '../../../constants/RoutesPaths'
import {AccessoriesForm} from "./AccessoriesForm";

export function OrderEntryForm({order}) {
  const {user} = useContext(AuthContext)
  const [showSummaryModal, setShowSummaryModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [confirmationError, setConfirmationError] = useState(false)
  const [submitOnSummary, setSubmitOnSummary] = useState(false)
  const [isLoadingInWaiting, setIsLoadingInWaiting] = useState(false)
  const defaultDeliveryFee = parseFloat(
    user.deliveryFees.max === '0.00'
      ? user.deliveryFees.min
      : user.deliveryFees.max,
  ).toFixed(2)
  const [amountMax, setAmountMax] = useState(250)
  const navigate = useNavigate()
  const [isDirty, setIsDirty] = useState(true)

  const validationSchema = object().shape({
    receiver: object().shape({
      isMourningOccasion: boolean(),
      lastname: string()
        .min(2, 'Ce champ doit contenir au minimum 2 caractères.')
        .required('Ce champ est requis'),
      address1: string()
        .min(2, 'Ce champ doit contenir au minimum 2 caractères.')
        .required('Ce champ est requis'),
      zipcode: string()
        .required('Ce champ est requis')
        .when('country', {
          is: (value) => value === 'F',
          then: (schema) =>
            schema.min(5, 'Ce champ doit contenir au minimum 5 caractères.'),
        }),
      city: string()
        .min(2, 'Ce champ doit contenir au minimum 2 caractères.')
        .required('Ce champ est requis'),
      country: string().ensure().required('Ce champ est requis'),
      tel: string()
        .when('country', {
          is: (value) => value === 'F',
          then: (schema) =>
            schema.matches(
              /^(0|\+\d{0,2}|00\d{0,2})?([0-9][\s.-]?){5,9}$/,
              'Ce champ doit contenir un téléphone valide.',
            ),
        })
        .when('isMourningOccasion', {
          is: false,
          then: (schema) => schema.required('* Ce champ est requis.'),
        }),
      phone: string().matches(
        /^(0|\+\d{0,2}|00\d{0,2})?([0-9][\s.-]?){5,9}$/,
        'Ce champ doit contenir un téléphone valide.',
      ),
    }),
    sender: object().shape({
      lastname: string()
        .min(2, 'Ce champ doit contenir au minimum 2 caractères.')
        .required('Ce champ est requis'),
      tel: string()
        .matches(
          /^(0|\+\d{0,2}|00\d{0,2})?([0-9][\s.-]?){5,9}$/,
          'Ce champ doit contenir un numéro téléphone valide.',
        )
        .required('* Ce champ est requis.'),
    }),
    scompo: object().shape({
      category: string().ensure().required('* Ce champ est requis.'),
      description: string()
        .min(2, 'Ce champ doit contenir au minimum 2 caractères.')
        .required('* Ce champ est requis.'),
    }),
    occasion: string().ensure().required('* Ce champ est requis.'),
    delivery: object().shape({
      date: date()
        .required('* Ce champ est requis.')
        .min(
          new Date(new Date().setHours(0, 0, 0)),
          'La date indiquée est incorrecte',
        ),
      moment: number().required('* Ce champ est requis.'),
    }),
    reference: string().ensure(),
    amountvat: number()
      .typeError('Ce champ doit être un nombre valide.')
      .when('refcatalogue', {
        is: (value) => value || value !== '',
        then: (schema) =>
          schema
            .min(20, 'Le montant doit être supérieur à 20 €')
            .max(amountMax, `Le montant doit être inférieur à ${amountMax} €`),
      }),
  })

  const formik = useFormik({
    initialValues: {
      idsenattente: (order && order.idsenattente) || '',
      receiver: {
        isMourningOccasion: false,
        add_client_dest: (order && order.receiver.add_client_dest) || true,
        idclient: (order && order.receiver.idclient) || '',
        companyname: (order && order.receiver.companyname) || '',
        lastname: (order && order.receiver.lastname) || '',
        firstname: (order && order.receiver.firstname) || '',
        address1: (order && order.receiver.address1) || '',
        address2: (order && order.receiver.address2) || '',
        address3: (order && order.receiver.address3) || '',
        zipcode: (order && order.receiver.zipcode) || '',
        city: (order && order.receiver.city) || '',
        country: (order && order.receiver.country) || 'F',
        countryLabel: (order && order.receiver.countryLabel) || 'FRANCE',
        countryId: 72,
        tel: (order && order.receiver.tel) || '',
        phone: (order && order.receiver.phone) || '',
      },
      sender: {
        add_client_exp: (order && order.sender.add_client_exp) || true,
        companyname: (order && order.sender.companyname) || '',
        lastname: (order && order.sender.lastname) || '',
        firstname: (order && order.sender.firstname) || '',
        tel: (order && order.sender.tel) || '',
        phone: (order && order.sender.phone) || '',
        email: (order && order.sender.email) || '',
        anonymous: (order && order.sender.anonymous) || false,
      },
      message: (order && order.message) || '',
      ruban: (order && order.ruban) || '',
      hasRibbon: (order && order.ruban.length > 0) || false,
      scompo: {
        id: (order && order.scompo.id) || '',
        reference: (order && order.scompo.reference) || '',
        description: (order && order.scompo.description) || '',
        category: (order && order.scompo.category) || '',
        categoryLabel: (order && order.scompo.categoryLabel) || '',
        amountmini: (order && order.scompo.amountmini) || 0,
        amountmax: (order && order.scompo.amountmax) || '',
        vat: (order && order.scompo.vat) || '',
        label: (order && order.scompo.label) || '',
        hasAvailableDates: true,
      },
      bubble: (order && order.bubble) || false,
      occasion: (order && parseInt(order.occasion, 10)) || 11,
      occasionLabel: (order && order.occasionLabel) || 'Autres',
      delivery: {
        workplace: (order && order.delivery.workplace) || false,
        date:
          (order &&
            order.delivery.date !== undefined &&
            parseISO(order.delivery.date)) ||
          null,
        moment: (order && parseInt(order.delivery.moment, 10)) || 3,
        momentLabel: (order && order.delivery.momentLabel) || 'JOURNEE',
        hour: (order && order.delivery.hour) || '',
        minutes: (order && order.delivery.minutes) || '',
        mourningplace: (order && order.delivery.place) || '',
        information: (order && order.delivery.information) || '',
        dateStart: new Date(),
        dateEnd: addDays(new Date(), 73),
      },
      amountvat: (order && parseFloatCustom(order.amountvat - order.totalAmountAccessories, 2)) || 0.0,
      transmissioncosts:
        (order && parseFloat(order.transmissioncosts).toFixed(2)) ||
        defaultDeliveryFee,
      vat: (order && order.vat) || '',
      codex: (order && order.codex) || '',
      codexLabel: (order && order.codexLabel) || '',
      composition: (order && order.composition) || '',
      totalamount: (order && parseFloat(order.totalamount).toFixed(2)) || 0.0,
      accessoriesMap: new Map(),
      accessories: [], // Permet d'envoyer les données lors du POST du formulaire
      numberOfAccessories: 0,
      totalAmountAccessories: (order && parseFloatCustom(order.totalAmountAccessories, 2)) || 0.0
    },
    validationSchema,
    validateOnChange: true,
    submitCount: 2,
    onSubmit: (values) => {
      setShowSummaryModal(true)
      values.accessories = [...values.accessoriesMap.values()];

      if (submitOnSummary) {
        submitOrder(values)
          .then((result) => {
            setIsDirty(false)
            setShowSummaryModal(false)
            setShowConfirmationModal(true)
          })
          .catch((error) => {
            setShowSummaryModal(false)
            setConfirmationError(true)
            setShowConfirmationModal(true)
          })
      }
    },
  })

  async function inWaiting(values) {
    setIsDirty(false)
    setIsLoadingInWaiting(true)
    formik.setSubmitting(true)
    values.accessories = [...values.accessoriesMap.values()];
    
    await CommandeAPI.postOrderNew(user.CodeFl, 'enattente', values).then(
      (result) => {
        if (result.data) {
          formik.dirty = false
          setIsLoadingInWaiting(false)
          navigate(TRANSMISSIONS_PATH)
        }
      },
    )
  }

  async function submitOrder(values) {
    await CommandeAPI.postOrderNew(user.CodeFl, 'confirmer', values)
  }

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => {
      return (!formik.isSubmitting && Object.values(formik.touched))
    }
  )

  useEffect(() => {
    delete formik.errors.amountvat
    delete formik.touched.amountvat

    if (
      formik.values.scompo.reference &&
      formik.values.scompo.reference !== ''
    ) {
      const amountMax = parseFloat(formik.values.scompo.amountmax)

      if (!Number.isNaN(amountMax)) {
        setAmountMax(amountMax * 2)
      }
    }

    if (formik.values.scompo.reference === '') {
      setAmountMax(250)

      if (parseInt(formik.values.occasion, 10) === 5) {
        setAmountMax(500)
      }
    }
  }, [
    formik.values.scompo.amountmax,
    formik.values.scompo.reference,
    formik.values.scompo.description,
    formik.values.occasion,
  ])

  useEffect(() => {
    if (blocker.state === 'blocked') {
      // eslint-disable-next-line
      if(window.confirm('Les modifications que vous avez apportées ne seront peut-être pas enregistrées.')) {
        blocker.proceed()
      } else {
        blocker.reset()
      }
    }
  }, [blocker])

  useBeforeunload((!formik.isSubmitting && Object.values(formik.touched)) ? (evt) => evt.preventDefault() : null)

  return (
    <>
      <Title title="Saisir une commande" />

      <Box
        boxName="commande-infos"
        boxClassNames="mb-3"
        boxContentPadding="px-3"
        boxContentClassNames="fs-14 py-2 rounded"
        boxContentBg="grey-light"
        content={
          <>
            <div>
              <FontAwesomeIcon
                icon="info-circle"
                color="var(--primary)"
                size="lg"
                className="mr-2"
              />
              <span className="ff-bold">Information :</span> Une nouvelle étape
              de <span className="ff-bold">récapitulatif de commande</span>{' '}
              vient d'être mise en place. Elle vous permet de contrôler les
              informations que vous avez saisi avant la validation de la
              commande.
            </div>
          </>
        }
      />
      <Col xs={12} className={'order'}>
        {isDirty && <Dirty formik={formik} />}
        <Form
          id={'order_form'}
          onSubmit={(event) => {
            event.preventDefault()
            formik.handleSubmit()
          }}>
          <Row>
            <ReceiverForm formik={formik} colSm={6} colXs={12} />
            <Col xs={12} sm={6}>
              <Row>
                <SenderForm formik={formik} colSm={12} colXs={12} />
              </Row>
              <Row>
                <MessageForm formik={formik} colSm={12} colXs={12} />
              </Row>
            </Col>
          </Row>
          <Row>
            <ProductForm formik={formik} colXs={12} colSm={6} />
            <AccessoriesForm formik={formik} colXs={12} colSm={6} order={order} />
          </Row>
          <Row>
            <DeliveryForm formik={formik} colXs={12} colSm={6} />
            <PriceForm formik={formik} colXs={12} colSm={6} />
          </Row>
          <Row className={'mb-5'}>
            <Col xs={12} className={'d-flex justify-content-end'}>
              <Button
                className={'btn btn-secondary'}
                size={'lg'}
                onClick={() => inWaiting(formik.values)}>
                {isLoadingInWaiting ? (
                  <Spinner
                    animation={'border'}
                    as={'span'}
                    role={'status'}
                    aria-hidden={true}
                  />
                ) : (
                  'Mettre en attente'
                )}
              </Button>
              <Button
                type={'submit'}
                className={'btn btn-primary ml-3'}
                size={'lg'}>
                Valider
              </Button>
            </Col>
          </Row>
          <SummaryModal
            orderValues={formik.values}
            show={showSummaryModal}
            onHide={() => setShowSummaryModal(false)}
            onSubmit={() => setSubmitOnSummary(true)}
          />
          <ConfirmationModal
            show={showConfirmationModal}
            onHide={() => setShowConfirmationModal(false)}
            error={confirmationError}
          />
        </Form>
      </Col>
    </>
  )
}

OrderEntryForm.propTypes = {
  order: PropTypes.object,
}
