import {format} from 'date-fns'
import Nav from 'react-bootstrap/Nav'
import Accordion from 'react-bootstrap/Accordion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {useEffect} from 'react'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {useSelector} from 'react-redux'

import NavItemCustom from '../../../router/NavItemCustom'
import SideMenuFooter from './SideMenuFooter'
import SideMenuHeader from './SideMenuHeader'
import SideMenuUser from './SideMenuUser'

import Sonnerie from '../../../assets/audio/sonnerie_v2.mp3'
import {sideMenuManager} from '../../../hooks/MenuManager'
import EasiwareForm, {EASIWARE_ID_MODAL} from '../form/easiware/EasiwareForm'
import {
  SIDE_MENU_AIDE,
  SIDE_MENU_BOUTIQUE,
  SIDE_MENU_MES_COMMANDES,
  SIDE_MENU_MES_OUTILS,
  SIDE_MENU_MON_COMPTE,
  SIDE_MENU_MON_FLORAJET,
  SIDE_MENU_PARAMETRES,
  SIDE_MENU_SOFTWARE,
} from '../../../constants/SideMenuConstants'
import {useNotifications} from '../../../hooks/useNotifications'
import SideMenuDelivery from './SideMenuDelivery'
import {FlowerFeastButton} from '../flower_feast/FlowerFeastButton'
import SideMenuToggle from './SideMenuToggle'
import {selectSideMenu} from '../../../slice/sideMenuSlice'
import SideMenuIcon from './SideMenuIcon'
import {useStoreExecutions} from "../../../store/useStoreExecutions";
import {useStoreSavs} from "../../../store/useStoreSavs";
import {INTERVAL_RELOAD_SAVS} from "../../../constants/Constants";
import {useMercureSubscriber} from "../../../hooks/useMercureSubscriber";

const SideMenu = () => {
  const {
    state,
    toggleMenu,
    isAdmin,
    handleNavItem,
    handleModalShow,
    contactFormOnMount,
    user,
  } = sideMenuManager();

  const {
    accordionDefaultKey,
    accordionActiveKey,
    subAccordionActiveKey,
  } = useSelector(selectSideMenu);

  const {handlePlay} = useNotifications();
  const executionsInPendingCount = useStoreExecutions((state) => state.executionsInPendingCount);
  const getSavs = useStoreSavs((state) => state.getSavs);
  const savsOpenedCount = useStoreSavs((state) => state.savsOpenedCount);
  const {result: newSavs} = useMercureSubscriber(['/savs/news']);

  // Permet de charger une première fois les données avec Zustand
  useEffect(() => {
    getSavs(user);

    if (newSavs.data && newSavs.data.count > 0) {
      getSavs(user, true);
    }
  }, [newSavs]);

  return (
    <>
      <Row className={'sticky-top'}>
        <Col>
          <Row>
            <Col>
              <SideMenuHeader onClick={toggleMenu} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Accordion
                defaultActiveKey={accordionDefaultKey}
                activeKey={accordionActiveKey}
                className="d-grid">
                <NavItemCustom
                  eventKey="0"
                  toPathname="tableau-de-bord"
                  onClick={handleNavItem}
                  classnamelink="bg-orange btn btn-block text-left py-1"
                  iconName="home"
                  title="Mon tableau de bord"
                />

                <SideMenuToggle
                  eventKey={SIDE_MENU_MON_FLORAJET.eventKey}
                  size={'lg'}>
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <div>
                      <Image
                        src="images/logo-f-white-sm.svg"
                        className="mr-1"
                      />
                      Mon florajet
                    </div>
                    <SideMenuIcon eventKey={SIDE_MENU_MON_FLORAJET.eventKey} />
                  </div>
                </SideMenuToggle>

                <Accordion.Collapse eventKey="1">
                  <>
                    {/* Inside accordion 1 */}
                    <Accordion
                      defaultActiveKey={SIDE_MENU_MES_COMMANDES.eventKey}
                      activeKey={subAccordionActiveKey}
                      className="d-grid">
                      <SideMenuToggle
                        eventKey={SIDE_MENU_MES_COMMANDES.eventKey}
                        lvl={2}>
                        <div className="d-flex flex-row align-items-center justify-content-between">
                          <div>
                            <SideMenuIcon
                              eventKey={SIDE_MENU_MES_COMMANDES.eventKey}
                              lvl={2}
                            />
                            Mes commandes
                          </div>
                        </div>
                      </SideMenuToggle>

                      <Accordion.Collapse
                        eventKey={SIDE_MENU_MES_COMMANDES.eventKey}>
                        <div>
                          <NavItemCustom
                            eventKey="3"
                            toPathname="executions"
                            onClick={toggleMenu}
                            classnamelink="pl-5 py-1"
                            title="Mes exécutions"
                            badge={
                              executionsInPendingCount > 0
                                ? executionsInPendingCount
                                : false
                            }
                          />

                          <NavItemCustom
                            eventKey="2"
                            toPathname="transmissions"
                            onClick={toggleMenu}
                            classnamelink="pl-5 py-1"
                            title="Mes transmissions"
                          />

                          {user.constraints && user.constraints.transmitter && (
                            <NavItemCustom
                              eventKey="4"
                              toPathname="saisir-commande"
                              onClick={toggleMenu}
                              classnamelink="pl-5 py-1"
                              title="Saisir une commande"
                            />
                          )}

                          <NavItemCustom
                            eventKey="8"
                            toPathname="sav"
                            onClick={toggleMenu}
                            classnamelink="pl-5 py-1"
                            title="Mes SAV"
                            badge={
                              savsOpenedCount > 0
                                ? savsOpenedCount
                                : false
                            }
                          />
                        </div>
                      </Accordion.Collapse>
                      {/* End Inside accordion 1 */}

                      {/* Inside accordion 2 */}
                      <SideMenuToggle
                        eventKey={SIDE_MENU_MON_COMPTE.eventKey}
                        lvl={2}>
                        <div className="d-flex flex-row align-items-center justify-content-between">
                          <div>
                            <SideMenuIcon
                              eventKey={SIDE_MENU_MON_COMPTE.eventKey}
                              lvl={2}
                            />
                            Mon compte
                          </div>
                        </div>
                      </SideMenuToggle>

                      <Accordion.Collapse
                        eventKey={SIDE_MENU_MON_COMPTE.eventKey}>
                        <div>
                          <NavItemCustom
                            eventKey="6"
                            toPathname="magasin"
                            onClick={toggleMenu}
                            classnamelink="pl-5 py-1"
                            title="Mon magasin"
                          />

                          {isAdmin && (
                            <NavItemCustom
                              eventKey="7"
                              toPathname="prelevements"
                              onClick={toggleMenu}
                              classnamelink="pl-5 py-1"
                              title="Ma comptabilité"
                            />
                          )}

                          <NavItemCustom
                            eventKey="10"
                            toPathname="pointages"
                            onClick={toggleMenu}
                            classnamelink="pl-5 py-1"
                            title="Mes pointages"
                          />

                          <NavItemCustom
                            eventKey="11"
                            toPathname="clients"
                            onClick={toggleMenu}
                            classnamelink="pl-5 py-1"
                            title="Mes clients"
                          />

                          {user.constraints && user.constraints.accessories
                            && (<NavItemCustom
                                  eventKey="29"
                                  toPathname="accessories/stock"
                                  onClick={toggleMenu}
                                  classnamelink="pl-5 py-1"
                                  title="Mon stock d'accessoires"
                              />)
                          }
                        </div>
                      </Accordion.Collapse>
                      {/* End Inside accordion 2 */}

                      {/* Inside accordion 3 */}
                      <SideMenuToggle
                        eventKey={SIDE_MENU_BOUTIQUE.eventKey}
                        lvl={2}>
                        <div className="d-flex flex-row align-items-center justify-content-between">
                          <div>
                            <SideMenuIcon
                              eventKey={SIDE_MENU_BOUTIQUE.eventKey}
                              lvl={2}
                            />
                            La boutique Florajet
                          </div>
                        </div>
                      </SideMenuToggle>

                      <Accordion.Collapse
                        eventKey={SIDE_MENU_BOUTIQUE.eventKey}>
                        <div>
                          {format(new Date(), 'yyyy-MM-dd') >= '2024-08-05' &&
                          format(new Date(), 'yyyy-MM-dd') <= '2024-08-18' ? (
                            <NavItemCustom
                              eventKey="13"
                              toPathname=""
                              onClick={toggleMenu}
                              classnamelink="pl-5 py-1 text-danger"
                              title="Mes achats (fermé du 05/08/24 au 18/08/24)"
                            />
                          ) : (
                            <NavItemCustom
                              eventKey="13"
                              toPathname="mes-achats"
                              onClick={toggleMenu}
                              classnamelink="pl-5 py-1"
                              title="Mes achats"
                            />
                          )}

                          <NavItemCustom
                            eventKey="14"
                            toPathname="historique"
                            onClick={toggleMenu}
                            classnamelink="pl-5 py-1"
                            title="Mon historique"
                          />
                        </div>
                      </Accordion.Collapse>
                    </Accordion>
                    {/* End Inside accordion 3 */}
                  </>
                </Accordion.Collapse>

                <SideMenuToggle
                  eventKey={SIDE_MENU_MES_OUTILS.eventKey}
                  size={'lg'}>
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <div>
                      <FontAwesomeIcon
                        icon="tools"
                        color="white"
                        className="mr-1"
                      />
                      Mes outils
                    </div>
                    <SideMenuIcon eventKey={SIDE_MENU_MES_OUTILS.eventKey} />
                  </div>
                </SideMenuToggle>

                <Accordion.Collapse eventKey={SIDE_MENU_MES_OUTILS.eventKey}>
                  <>
                    <NavItemCustom
                      eventKey="16"
                      toPathname="catalogue"
                      onClick={toggleMenu}
                      classnamelink="pl-5 py-1"
                      title="Catalogue"
                    />
                    <NavItemCustom
                      eventKey="17"
                      toPathname="documentation"
                      onClick={toggleMenu}
                      classnamelink="pl-5 py-1"
                      title="Documentation"
                    />
                    {/* <NavItemCustom */}
                    {/*    eventKey="22" */}
                    {/*    toPathname="top-10" */}
                    {/*    onClick={toggleMenu} */}
                    {/*    classnamelink="pl-5 py-1" */}
                    {/*    title="Top 10 des bouquets" */}
                    {/* /> */}
                    <NavItemCustom
                      eventKey="27"
                      toPathname="partners"
                      onClick={toggleMenu}
                      classnamelink="pl-5 py-1"
                      title="Partenaires"
                    />
                  </>
                </Accordion.Collapse>

                <SideMenuToggle eventKey={SIDE_MENU_AIDE.eventKey} size={'lg'}>
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <div>
                      <FontAwesomeIcon
                        icon="question-circle"
                        color="white"
                        className="mr-1"
                      />
                      Aide
                    </div>
                    <SideMenuIcon eventKey={SIDE_MENU_AIDE.eventKey} />
                  </div>
                </SideMenuToggle>

                <Accordion.Collapse eventKey={SIDE_MENU_AIDE.eventKey}>
                  <>
                    <a
                      href={'https://monflorajet.easi.care/fr-FR/Categories/'}
                      target={'_blank'}
                      rel="noreferrer"
                      onClick={toggleMenu}
                      className="pl-5 py-1 nav-link"
                      title="FAQ">
                      FAQ
                    </a>
                    <NavItemCustom
                      eventKey="21"
                      toPathname="videos"
                      onClick={toggleMenu}
                      classnamelink="pl-5 py-1"
                      title="Vidéos"
                    />
                    <Accordion defaultActiveKey={SIDE_MENU_SOFTWARE.eventKey}>
                      <SideMenuToggle
                        eventKey={SIDE_MENU_SOFTWARE.eventKey}
                        lvl={2}>
                        <div>
                          <SideMenuIcon
                            eventKey={SIDE_MENU_SOFTWARE.eventKey}
                            lvl={2}
                          />
                          Logiciels
                        </div>
                      </SideMenuToggle>
                      <Accordion.Collapse
                        eventKey={SIDE_MENU_SOFTWARE.eventKey}>
                        <div>
                          <Nav.Link
                            href={
                              'https://www.microsoft.com/fr-fr/microsoft-365/microsoft-teams/download-app'
                            }
                            className={'pl-5 py-1'}
                            onClick={toggleMenu}
                            eventKey={'26'}
                            target={'_blank'}>
                            Microsoft Teams
                          </Nav.Link>
                          <Nav.Link
                            href={
                              'https://get.adobe.com/fr/reader/otherversions/'
                            }
                            className={'pl-5 py-1'}
                            onClick={toggleMenu}
                            eventKey={'26'}
                            target={'_blank'}>
                            Adobe Reader PDF
                          </Nav.Link>
                          <Nav.Link
                            href={'https://www.foxit.com/fr/pdf-reader/'}
                            className={'pl-5 py-1'}
                            onClick={toggleMenu}
                            eventKey={'26'}
                            target={'_blank'}>
                            PDF Foxit Reader
                          </Nav.Link>
                        </div>
                      </Accordion.Collapse>
                    </Accordion>
                  </>
                </Accordion.Collapse>

                <Nav.Item>
                  <Nav.Link
                    active={state.modalShow}
                    eventKey="9"
                    onClick={() => handleModalShow(true)}
                    className="bg-primary btn btn-block text-left mt-1 py-1">
                    <FontAwesomeIcon
                      icon={'comments'}
                      color="white"
                      className="mr-1"
                    />
                    Envoyer un message
                  </Nav.Link>
                </Nav.Item>

                <SideMenuToggle
                  eventKey={SIDE_MENU_PARAMETRES.eventKey}
                  size={'lg'}>
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <div>
                      <FontAwesomeIcon
                        icon="cog"
                        color="white"
                        className="mr-1"
                      />
                      Paramètres
                    </div>
                    <SideMenuIcon eventKey={SIDE_MENU_PARAMETRES.eventKey} />
                  </div>
                </SideMenuToggle>

                <Accordion.Collapse eventKey={SIDE_MENU_PARAMETRES.eventKey}>
                  <>
                    {isAdmin && (
                      <>
                        <NavItemCustom
                          eventKey="14"
                          toPathname="Utilisateurs"
                          onClick={toggleMenu}
                          classnamelink="pl-5 py-1"
                          title="Utilisateur"
                        />

                        <NavItemCustom
                          eventKey="15"
                          toPathname="livreurs"
                          onClick={toggleMenu}
                          classnamelink="pl-5 py-1"
                          title="Livreurs"
                        />
                      </>
                    )}

                    <Nav.Item>
                      <Nav.Link
                        active={state.modalShow}
                        eventKey="24"
                        onClick={() => handlePlay()}
                        className="pl-5 py-1">
                        Tester la sonnerie
                      </Nav.Link>
                    </Nav.Item>
                    <audio className="sonnerie" autoPlay={true} muted={true}>
                      <source src={Sonnerie}></source>
                    </audio>
                  </>
                </Accordion.Collapse>
              </Accordion>
            </Col>
          </Row>

          <Row>
            <Col>
              <FlowerFeastButton eventKey={'28'} />
            </Col>
          </Row>
          <Row>
            <Col>
              <SideMenuUser />
            </Col>
          </Row>
          <Row>
            <Col>
              <SideMenuDelivery />
            </Col>
          </Row>
          <Row>
            <Col>
              <SideMenuFooter />
            </Col>
          </Row>
        </Col>
      </Row>

      <EasiwareForm
        formId={4}
        state={state}
        handleModalShow={handleModalShow}
        onMount={() => contactFormOnMount(EASIWARE_ID_MODAL)}
        headerTitle={'Nouveau message'}
      />
    </>
  )
}

SideMenu.propTypes = {}

export default SideMenu
